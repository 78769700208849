/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
/* Provide sufficient contrast against white background */

html,
body {
  height: 100%;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

button {
  touch-action: manipulation;
}

.fixed-top {
  z-index: 1000 !important;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: white
}

.mat-form-field-ripple {
  background-color: #fff
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: white
}

/* .mat-focused .mat-form-field-label {
  color: white;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background-color: white;
} */

.custom-search-form>.mat-input-element {
  caret-color: #ffffff;
}

.custom-search-form>.mat-form-field-appearance-legacy .mat-form-field-label {
  color: white;
}

.custom-search-form>.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: white;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/*CUSTOM*/
ui-switch {
  position: relative;
  top: 5px;
}

.switch-container {
  margin-right: 11px;
  margin-bottom: 4px;
}

.page-title {
  padding: 1.5rem 0;
}

.navbar-dark {
  background-color: #4e4e4e;
}

/* Addresses that live in the left-hand columns of detail pages */
.address-ul {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}

.pointer {
  cursor: pointer;
}

/* Kendo Grid Overrides */
.k-grid th,
.k-grid td {
  padding: .4rem .55rem !important;
  font-size: 13px !important;
}

.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
  height: calc(1.5em + 10px);
  padding: 0;
  top: 3px;
}

/* VALIDATION */
.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42A948;
  /* green */
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

.ng-select .ng-select-container {
  border-radius: 0;
}

.dropdown-menu {
  padding: 0px;
}

.dropdown-item {
  padding: 13px 15px;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: .2125rem .55rem;
  font-size: .75rem;
  line-height: 1.6667;
  border-radius: .125rem;
}

.breadcrumb-link-color {
  color: #333333;
}

.switch-label {
  margin-right: 3px;
}

.modal-footer {
  border-top: 1px solid #e0e0e0 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.dropdown-divider {
  margin: 0px !important;
}

dd {
  min-height: 20px;
}

@media (max-width: 575.98px) {
  .col-form-label {
    text-align: left !important;
  }

  dt.text-right,
  dd.text-right {
    text-align: left !important;
  }

}

.compact-nav .nav-tabs {
  margin-bottom: 0px;
}

.compact-nav .tab-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .1875rem;

  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);

  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  margin-bottom: 0 !important;

  border-top: 0 !important;

  flex: 1 1 auto;
  padding: 1.25rem;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.ng-select.ng-select-single .ng-select-container {
  min-height: 32px;
  height: 32px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.table-condensed td,
.table-condensed th {
  padding: .4rem 1rem;
}

.switch-container button.switch.switch-small {
  position: relative;
  top: -10px;
}
